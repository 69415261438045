<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <AppHeader title="Browse<br>your daily news" @onSearch="searchNews" />

      <div class="categories">
        <ion-chip v-for="(c, index) in categories" :key="index" :color="category === c ? 'primary' : undefined" @click="setCategory(c)">
          {{ c }}
        </ion-chip>
      </div>

      <ion-list class="articles">
        <template v-if="loading">
          <NewsListSkeletonItem v-for="index in 10" :key="index" />
        </template>
        <template v-else>
          <NewsListItem v-for="(newsItem, index) in newsItems" :key="index" :news-item="newsItem" :id="index" />
        </template>
      </ion-list>
      <ion-infinite-scroll @ionInfinite="ionInfinite">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
  IonContent,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonChip,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  InfiniteScrollCustomEvent
} from '@ionic/vue';
import AppHeader from '@/components/AppHeader.vue';
import NewsListItem from '@/components/NewsListItem.vue';
import NewsListSkeletonItem from '@/components/NewsListSkeletonItem.vue';
import { getNews, NewsItem } from '@/data/news';

const categories = ref<string[]>([
  'General',
  'Auto',
  'Sports',
  'Economy',
  'Politics',
]);

const category = ref<string>('General');
const page = ref<number>(1);
const keyword = ref<string>('');

const loading = ref<boolean>(false);

const newsItems = ref<NewsItem[]>([]);
const totalNewsItems = ref<number>(0);

const fetchNews = async () => {
  loading.value = true;
  const response = await getNews(category.value.toLowerCase(), keyword.value, page.value);
  loading.value = false;
  newsItems.value = response.news;
  totalNewsItems.value = response.total;
};

fetchNews();

const setCategory = (c: string) => {
  category.value = c;
  fetchNews();
};

const searchNews = (ev: CustomEvent) => {
  keyword.value = ev.detail.value;
  page.value = 1;
  fetchNews();
};

const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
  if (newsItems.value.length >= totalNewsItems.value) {
    ev.target.complete();
    return;
  }

  page.value++;

  const response = await getNews(category.value.toLowerCase(), keyword.value, page.value);

  newsItems.value = [...newsItems.value, ...response.news];
  totalNewsItems.value = response.total;

  ev.target.complete();
};

const refresh = async (ev: CustomEvent) => {
  await fetchNews();
  ev.detail.complete();
};
</script>

<style lang="scss" scoped>
.categories {
  white-space: nowrap;
  overflow-x: auto;
  padding: 0 16px;
  margin-bottom: 16px;
}

.articles {
  padding: 16px;
  background: #fdfdfd;
}
</style>