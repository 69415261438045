<template>
  <div v-if="newsItem">
    <div class="card" :id="`open-modal-${id}`">
      <div class="image">
        <img v-if="newsItem.image" :src="newsItem.image" alt="News Image" />
        <img v-else src="https://via.placeholder.com/150" alt="Placeholder Image" />
      </div>
      <div class="text">
        <h2>
          {{ newsItem.title }}
        </h2>
        <div class="details">
          <span class="site">{{ domain }}</span>
          <span class="divider">•</span>
          <span class="date">{{ timeAgo }}</span>
        </div>
      </div>
    </div>
    <ion-modal ref="modal" :trigger="`open-modal-${id}`" :initial-breakpoint="0.875" :breakpoints="[0, 0.875, 1]">
      <ion-content class="ion-padding modal">
        <div class="image">
          <img v-if="newsItem.image" :src="newsItem.image" alt="News Image" />
        </div>
        <div class="title">
          <h2>{{ newsItem.title }}</h2>
        </div>
        <div class="content">
          <p>{{ newsItem.description }}</p>
        </div>
        <div class="footer">
          <div class="actions">
            <ion-button @click="shareArticle" expand="block" color="dark">
              <ion-icon :icon="shareSocialOutline" slot="start"></ion-icon>
              Share
            </ion-button>
            <ion-button @click="saveArticle" expand="block" color="dark">
              <ion-icon :icon="isSaved ? bookmark : bookmarkOutline" slot="start"></ion-icon>
              {{ isSaved ? 'Saved' : 'Save' }}
            </ion-button>
          </div>
          <div class="visit">
            <ion-button @click="openArticle" expand="block">Read more</ion-button>
          </div>
        </div>
      </ion-content>
    </ion-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { Preferences } from '@capacitor/preferences';
import {
  IonModal,
  IonContent,
  IonButton
} from '@ionic/vue';
import { shareSocialOutline, bookmark, bookmarkOutline } from 'ionicons/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { md5 } from 'js-md5';
import { NewsItem } from '@/data/news';
import { InAppBrowser } from '@capgo/inappbrowser'

const props = defineProps({
  newsItem: Object as () => NewsItem
});

dayjs.extend(relativeTime);

const id = ref(md5(props.newsItem?.link || ''));

const domain = computed(() => {
  if (props.newsItem?.link) {
    const url = new URL(props.newsItem.link);
    return url.hostname.replace('www.','');
  }

  return '';
});

const timeAgo = computed(() => {
  return props.newsItem?.date ? dayjs(props.newsItem.date).fromNow() : '';
});

const isSaved = ref(false);
const savedArticles = ref<NewsItem[]>([]);

const openArticle = () => {
  if (!props.newsItem) return;

  if (Capacitor.isNativePlatform()) {
    InAppBrowser.open({ url: props.newsItem.link });
  } else {
    window.open(props.newsItem.link, '_blank');
  }
};

const shareArticle = async () => {
  if (!props.newsItem) return;

  await Share.share({
    title: props.newsItem.title,
    text: props.newsItem.description,
    url: props.newsItem.link,
    dialogTitle: 'Share article'
  });
};

const saveArticle = async () => {
  if (!props.newsItem) return;

  if (isSaved.value) {
    savedArticles.value = savedArticles.value.filter((article: NewsItem) => article.link !== props.newsItem?.link);
  } else {
    savedArticles.value.push(props.newsItem);
  }

  isSaved.value = !isSaved.value;

  await Preferences.set({ key: 'savedArticles', value: JSON.stringify(savedArticles.value) });
};

Preferences.get({ key: 'savedArticles' }).then((result) => {
  if (result.value) {
    savedArticles.value = JSON.parse(result.value);
    isSaved.value = savedArticles.value.some((article: NewsItem) => article.link === props.newsItem?.link);
  }
});
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;

  .image {
    flex-shrink: 0;

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0 0 8px 0;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }

    .details {
      display: flex;
      gap: 8px;
      align-items: center;
      color: var(--ion-color-medium);
      padding-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .site {
        font-size: 0.875rem;
      }

      .date {
        font-size: 0.875rem;
      }
    }
  }
}

.modal {
  .image {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .actions {
    display: flex;
    gap: 8px;

    ion-button {
      width: 50%;
    }
  }
}
</style>
