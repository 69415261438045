/*
[
    {
        "title": "Vogue: \u03a3\u03c0\u03ac\u03bd\u03b9\u03bf \u03b5\u03be\u03ce\u03c6\u03c5\u03bb\u03bb\u03bf \u03b3\u03b9\u03b1 \u03c4\u03b7 \u03b2\u03c1\u03b5\u03c4\u03b1\u03bd\u03b9\u03ba\u03ae \u03ad\u03ba\u03b4\u03bf\u03c3\u03b7 - 40 \u03b4\u03b9\u03ac\u03c3\u03b7\u03bc\u03b5\u03c2 \u03b3\u03c5\u03bd\u03b1\u03af\u03ba\u03b5\u03c2 \u03c6\u03c9\u03c4\u03bf\u03b3\u03c1\u03b1\u03c6\u03ae\u03b8\u03b7\u03ba\u03b1\u03bd \u03bc\u03b1\u03b6\u03af",
        "description": "<span>\u039c\u03b5 \u03b1\u03c6\u03bf\u03c1\u03bc\u03ae \u03c4\u03b7\u03bd \u03b1\u03c0\u03bf\u03c7\u03ce\u03c1\u03b7\u03c3\u03b7 \u03c4\u03bf\u03c5 \u03b5\u03ba\u03b4\u03cc\u03c4\u03b7, \u0388\u03bd\u03c4\u03bf\u03c5\u03b1\u03c1\u03bd\u03c4 \u0388\u03bd\u03b9\u03bd\u03c6\u03bf\u03c5\u03bb, \u03bc\u03b5\u03c4\u03ac \u03b1\u03c0\u03cc 6 \u03c7\u03c1\u03cc\u03bd\u03b9\u03b1<\/span>",
        "link": "https:\/\/www.protothema.gr\/life-style\/article\/1464781\/vogue-spanio-exofullo-gia-ti-vretaniki-ekdosi-40-diasimes-gunaikes-fotografithikan-mazi\/?utm_source=rss",
        "date": "2024-02-09 10:22:04",
        "image": null
    },
    {
        "title": "\u0391\u03bd\u03b4\u03c1\u03bf\u03c5\u03bb\u03ac\u03ba\u03b7\u03c2: \u0397 \u03c0\u03b1\u03c4\u03c1\u03af\u03b4\u03b1 \u03bc\u03b1\u03c2 \u03b2\u03c1\u03af\u03c3\u03ba\u03b5\u03c4\u03b1\u03b9 \u03c3\u03b5 \u03c0\u03c1\u03c9\u03c4\u03bf\u03c6\u03b1\u03bd\u03ae \u03c3\u03c4\u03b5\u03b3\u03b1\u03c3\u03c4\u03b9\u03ba\u03ae \u03ba\u03c1\u03af\u03c3\u03b7",
        "description": "\u00ab\u0397 golden visa ''\u03c6\u03b1\u03c1\u03bc\u03ac\u03ba\u03b9'' \u03b3\u03b9\u03b1 \u03c7\u03b9\u03bb\u03b9\u03ac\u03b4\u03b5\u03c2 \u03bd\u03ad\u03bf\u03c5\u03c2\u00bb, \u03b5\u03af\u03c0\u03b5 \u03bf \u03c0\u03c1\u03cc\u03b5\u03b4\u03c1\u03bf\u03c2 \u03c4\u03bf\u03c5 \u03a0\u0391\u03a3\u039f\u039a",
        "link": "https:\/\/www.protothema.gr\/politics\/article\/1464779\/androulakis-i-patrida-mas-vrisketai-se-protofani-stegastiki-krisi\/?utm_source=rss",
        "date": "2024-02-09 10:20:00",
        "image": null
    },
*/

export interface NewsItem {
    title: string;
    description: string;
    link: string;
    date: string;
    image: string;
}

export const getNews = async (category: string, keyword: string, page = 1): Promise<{ news: NewsItem[], total: number }> => {
    const response = await fetch(`https://mlcpm7hg-news.patrik.sh/api/v1/news?category=${category}&keyword=${keyword}&page=${page}`);
    const data = await response.json();

    return data;
}